import * as React from "react";
import { Tabs as MuiTabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";

export function Tabs({ handleChange, tabTypes = [], selectedTab = 0 }) {
  return (
    <Box>
      <MuiTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        sx={{
          "& .MuiTabs-flexContainer": {
            gap: 3,
          },
        }}
        TabIndicatorProps={{
          style: { borderRadius: 3, height: 3 },
        }}
      >
        {tabTypes.map((tab) => (
          <Tab
            value={tab.id}
            key={tab.id}
            label={tab?.label}
            sx={{
              fontSize: 18,
              textTransform: "none",
              fontWeight: 500,
              color: "secondary.main",
            }}
          />
        ))}
      </MuiTabs>
    </Box>
  );
}

export function TabsWithButton({
  handleChange,
  tabTypes = [],
  selectedTab = 0,
}) {
  return (
    <MuiTabs
      value={selectedTab}
      onChange={handleChange}
      indicatorColor="secondary"
      sx={{
        alignItems: "center",
        width: "100%",
        "& .MuiTabs-flexContainer": {
          justifyContent: "center",
          gap: { xs: "1rem", md: "4rem" },
        },
        button: {
          color: "primary.main",
          fontFamily: "Montserrat",
          fontWeight: "600",
          borderRadius: "2rem",
          fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
          p: "0.8rem",
          minWidth: { xs: "6.5rem", sm: "8rem", md: "12rem" },
          minHeight: "auto",
          boxSizing: "border-box",
          lineHeight: 1,
          bgcolor: "#fff",
          border: "1.5px solid",
          borderColor: "primary.mediumLight",
          "&.Mui-selected": {
            bgcolor: "primary.main",
            color: "#fff",
          },
        },
      }}
      TabIndicatorProps={{
        style: { borderRadius: 3, height: 0 },
      }}
    >
      {tabTypes.map((tab) => (
        <Tab
          value={tab.id}
          key={tab.id}
          label={tab?.label}
          sx={{
            fontSize: 18,
            textTransform: "capitalize",
            bgcolor: "neutrals.300",
          }}
        />
      ))}
    </MuiTabs>
  );
}
